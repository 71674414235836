/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { BaseURL } from '../../Constant';
import OG from '../PMMConfiguration/OG/OG';
import Industry from '../PMMConfiguration/Industry/Industry';
import Capability from '../PMMConfiguration/Capability/Capability';
import Tower from '../PMMConfiguration/Tower/Tower';
import ServiceComponent from '../PMMConfiguration/ServiceComponent/ServiceComponent';
import BOILIMaster from '../PMMConfiguration/BOILI/BOILIMaster';
import KPA from '../PMMConfiguration/KPA/KPA';
import Lever from '../PMMConfiguration/Lever/Lever';
import Metrics from '../PMMConfiguration/Metrics/Metrics';
import RegionEntry from './Region/RegionEntry';
import NavigationConfirmation from '../../PMM/NavigationConfirmation';
import BenchmarkValidationPopup from './BenchmarkValidationPopup';
import { Route, Switch, Redirect, withRouter } from 'react-router';
import $ from 'jquery';
import downloadIcon from '../../images/icon-downloading.png';
import backArrowIcon from '../../images/icon-back-arrow.png';
import request from '../../services/Service';
import { Typeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import { connect } from 'react-redux';


var MasterListValues = [];

class PMMConfiguration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            MasterLists: [],
            MasterListsValue: [],
            SelectedType: "",
            isUnSaved: false,
            popUpDisplay: false,
            toURL: '',
            navigationPage: "",
            pageNames: [],
            showUpload: false,
            popUpBenchmark: false,
            pageTitle: [{ label: "", value: i18n.t('pMMConfigurationMasterConfig') }, { label: "OG", value: i18n.t('oG') }, { label: "Industry", value: i18n.t('industry') }, { label: "Region", value: i18n.t('region') }, { label: "Capability", value: i18n.t('Main Offering') }, { label: "Tower", value: i18n.t('Sub Offering') }, { label: "KPA", value: i18n.t('kpa') }, { label: "Lever", value: i18n.t('lever') }, { label: "BOI LI", value: i18n.t('boili') }, { label: "Service Component", value: i18n.t('Process') }]
        };
        this.isEditActive = this.isEditActive.bind(this);
        this.onNavigationClick = this.onNavigationClick.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.setDropValue = this.setDropValue.bind(this);
        this.SelectToConfigure = this.SelectToConfigure.bind(this);
        this.downloadDataDump = this.downloadDataDump.bind(this);
        this.DownloadtoExcelBenchmarkDetails = this.DownloadtoExcelBenchmarkDetails.bind(this);
        this.closeBenchmarkPopUp = this.closeBenchmarkPopUp.bind(this);
        this.downloadInvalidBenchmark = this.downloadInvalidBenchmark.bind(this);
        this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
    }

    componentDidMount() {

        try {
            var objThis = this;                        
            request("/MasterScreens/GetMasterScreenLabels?LanguageID=" + this.props.languageId, 'GET')
                .then(res => {
                    var MasterListValues = res
                    objThis.setState({ MasterListValues });
                })
                .catch(error => {
                    console.log('Error Encountered');
                });
            let path = this.props.location.pathname.split('/');
            this.setState({
                MasterLists: MasterListValues,
                SelectedType: path[2] != undefined ? this.state.pageTitle.filter(e => e.label == path[2])[0].value : "",
                pageNames: this.props.pageName
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectToConfigure(val) {

        try {
            if (this.state.isUnSaved) {
                this.setState({
                    popUpDisplay: true,
                    toURL: false,
                    navigationPage: val[0].label,
                    dropValue: val
                })
            }
            else {
                this.setState({
                    MasterListsValue: val,
                    SelectedType: val[0].label
                });

                this.props.history.push(`/PMMConfiguration/${val[0].text}`);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    showDownloadUpload(val) {

        try {
            if (val == true) {
                this.setState({ showUpload: true });
            }
            else {
                this.setState({ showUpload: false });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    setDropValue(val) {

        try {
            this.setState({
                MasterListsValue: val,
                isUnSaved: false
            });
            this.props.history.push(`/PMMConfiguration/${val}`);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /* Function captures the active state from the child pages */
    isEditActive(val) {
        debugger;
        try {
            if (val) {
                this.setState({
                    isUnSaved: true,
                    popUpDisplay: false
                })
            } else {

                this.setState({
                    isUnSaved: false,
                    popUpDisplay: false
                })
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Function prompts a pop up in case on unsaved data*/
    onNavigationClick(eve) {

        try {
            let navigationPage = eve.target.getAttribute('data-content');
            // if(navigationPage == i18n.t('userManagement')){
            //     window.tfo_event("PMM-MC-11", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == i18n.t('dealManagement')){
            //     window.tfo_event("PMM-MC-24", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            // if(navigationPage == i18n.t('pMMConfiguration')){
            //     window.tfo_event("PMM-MC-17", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            let url = eve.target.getAttribute('data-url');
            if (this.state.isUnSaved) {
                this.setState({
                    popUpDisplay: true,
                    toURL: url,
                    navigationPage: navigationPage
                })
            }
            else {
                if (navigationPage == "HOME") {
                    this.setState({
                        popUpDisplay: true,
                        toURL: url,
                        navigationPage: navigationPage
                    })
                }
                else {
                    this.props.history.push(url);
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    downloadDataDump() {

        try {            
            //window.tfo_event("PMM-D-12", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            var objThis = this;
            window.removeEventListener("beforeunload", this.unloadEvent);
            let scData = {
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            let jsonscData = JSON.stringify(scData);
            $("#loader-div").show();
            fetch(BaseURL + "/Common/DownloadtoExcelDataDump", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //'charset': 'utf-8',
                    Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
                    //"Cache-Control": "no-cache, no-store, must-revalidate",
                    //Pragma: "no-cache"
                },
                body : jsonscData
            })
            .then(response => response.blob())
                .then((blob) => {
                    const BlobUrl = window.URL.createObjectURL(blob);
                    var downLink = document.createElement("a");
                    downLink.href = BlobUrl;
                    downLink.download = "PMM_DataDump.xlsx";
                    document.body.appendChild(downLink);
                    downLink.click();
                    downLink.remove();
                    $("#loader-div").hide();
                    alert(i18n.t("filedownloaded"));
                    }
                )
            
            // request("/Common/DownloadtoExcelDataDump", 'POST', scData)
            //     .then(res => {

            //         var filePath = res.Table1[0].Value;
            //         window.location.href = filePath;
            //     })
            //     .catch(error => {
            //         console.log("Error Encountered");
            //         window.addEventListener("beforeunload", objThis.unloadEvent);
            //     });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    /*Function closes the popup */
    closeNavigationPopUp() {
        try {
            this.setState({
                popUpDisplay: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async DownloadtoExcelProjectPrioritization() {

        try {            
            //window.tfo_event("PMM-D-11", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            var SearchedString = "";
            let projData = {
                "UserName": this.props.UserName,
                "DealId": 0,
                "RegionID": 0,
                "TowerID": 0,
                "SCId": 0,
                "DeliveryLocationId": 0,
                "SearchedString": SearchedString,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            let jsonprojData = JSON.stringify(projData);
            $("#loader-div").show();
            fetch(BaseURL + "/Common/DownloadtoExcelProjectPrioritization", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //'charset': 'utf-8',
                    "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                },
                body : jsonprojData
            })
            .then(response => response.blob())
                .then((blob) => {
                    const BlobUrl = window.URL.createObjectURL(blob);
                    var downLink = document.createElement("a");
                    downLink.href = BlobUrl;
                    downLink.download = `PrioritizedProject.xlsx`;
                    document.body.appendChild(downLink);
                    downLink.click();
                    downLink.remove();
                    $("#loader-div").hide();
                    alert(i18n.t("filedownloaded"));
                    }
                )
            
            // await request("/Common/DownloadtoExcelProjectPrioritization", 'POST', projData)
            //     .then(res => {
            //         if (res.Table1[0].length == 0) {
            //             alert(i18n.t("norecordsfound"));
            //         }
            //         else {
            //             var filePath = res.Table1[0].Value;
            //             window.location.href = filePath;
            //         }
            //     })
            //     .catch(error => {
            //         console.log("Error Encountered");
            //     });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    async DownloadtoHistoryReport() {

        try {            
            //window.tfo_event("PMM-D-11", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            var SearchedString = "";
            let projData = {
                "UserName" : this.props.enterpriseId,
                "DealId": 0,
                "RegionID": 0,
                "TowerID": 0,
                "SCId": 0,
                "DeliveryLocationId": 0,
                "SearchedString": SearchedString,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            let jsonprojData = JSON.stringify(projData);
            $("#loader-div").show();
            fetch(BaseURL + "/Common/DownloadtoHistoryReport", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //'charset': 'utf-8',
                    "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                },
                body : jsonprojData
            })
            .then(response => response.blob())
                .then((blob) => {
                    const BlobUrl = window.URL.createObjectURL(blob);
                    var downLink = document.createElement("a");
                    downLink.href = BlobUrl;
                    downLink.download = `ActiveReport.xlsx`;
                    document.body.appendChild(downLink);
                    downLink.click();
                    downLink.remove();
                    $("#loader-div").hide();
                    alert(i18n.t("filedownloaded"));
                    }
                )
            
            // await request("/Common/DownloadtoExcelProjectPrioritization", 'POST', projData)
            //     .then(res => {
            //         if (res.Table1[0].length == 0) {
            //             alert(i18n.t("norecordsfound"));
            //         }
            //         else {
            //             var filePath = res.Table1[0].Value;
            //             window.location.href = filePath;
            //         }
            //     })
            //     .catch(error => {
            //         console.log("Error Encountered");
            //     });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async DownloadtoExcelActiveReport() {

        try {            
            //window.tfo_event("PMM-D-11", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            var SearchedString = "";
            let projData = {
                "UserName" : this.props.enterpriseId,
                "DealId": 0,
                "RegionID": 0,
                "TowerID": 0,
                "SCId": 0,
                "DeliveryLocationId": 0,
                "SearchedString": SearchedString,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            let jsonprojData = JSON.stringify(projData);
            $("#loader-div").show();
            fetch(BaseURL + "/Common/DownloadtoExcelActiveReport", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //'charset': 'utf-8',
                    "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                },
                body : jsonprojData
            })
            .then(response => response.blob())
                .then((blob) => {
                    const BlobUrl = window.URL.createObjectURL(blob);
                    var downLink = document.createElement("a");
                    downLink.href = BlobUrl;
                    downLink.download = `HistoryReport.xlsx`;
                    document.body.appendChild(downLink);
                    downLink.click();
                    downLink.remove();
                    $("#loader-div").hide();
                    alert(i18n.t("filedownloaded"));
                    }
                )
            
            // await request("/Common/DownloadtoExcelProjectPrioritization", 'POST', projData)
            //     .then(res => {
            //         if (res.Table1[0].length == 0) {
            //             alert(i18n.t("norecordsfound"));
            //         }
            //         else {
            //             var filePath = res.Table1[0].Value;
            //             window.location.href = filePath;
            //         }
            //     })
            //     .catch(error => {
            //         console.log("Error Encountered");
            //     });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async DownloadtoExcelSubmittedBOILI() {
        try {            
            //window.tfo_event("PMM-D-10", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            var type = "0";
            var that = this;

            let scData = {
                "UserName": this.props.enterpriseId,
                "DealId": 0,
                "RegionID": 0,
                "SCId": 0,
                "DeliveryLocationId": 0,
                "BOILIId": 0,
                "Type": type,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            let jsonscData = JSON.stringify(scData);
            $("#loader-div").show();
            fetch(BaseURL + "/Common/DownloadtoExcelSubmittedBOILI", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    //'charset': 'utf-8',
                    "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                },
                body : jsonscData
            })
            .then(response => response.blob())
                .then((blob) => {
                    console.log(blob);
                    const BlobUrl = window.URL.createObjectURL(blob);
                    var downLink = document.createElement("a");
                    downLink.href = BlobUrl;
                    downLink.download = `SubmittedBOILI.xlsx`;
                    document.body.appendChild(downLink);
                    downLink.click();
                    downLink.remove();
                    $("#loader-div").hide();
                    alert(i18n.t("filedownloaded"));
                    }
                )
            // .then(response => response.Table1[0].Value)
            // .then((res) => {
            //     console.log("Check Shravani" +res);
            // })
            // .then(response => {
            //     if (response.Table1[0].length == 0) {
            //         alert(i18n.t("norecordsfound"));
            //     }
            //     else {
            //         var filePath = response.Table1[0].Value;
            //         window.location.href = filePath;
            //     }
            // })
            
            // await request("/Common/DownloadtoExcelSubmittedBOILI", 'POST', scData)

            //     .then(response => {
            //         if (response.Table1[0].length == 0) {
            //             alert(i18n.t("norecordsfound"));
            //         }
            //         else {
            //             var filePath = response.Table1[0].Value;
            //             window.location.href = filePath;
            //         }
            //     })
            //     .catch(error => {
            //         console.log("Error Encountered");
            //     });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async DownloadtoExcelBenchmarkDetails() {

        try {            
            //window.tfo_event("PMM-D-16", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // await request("/Common/DownloadtoExcelBenchmarkDetails?GroupID=" + this.props.groupId + "&LanguageID=" + this.props.languageId, 'GET')
            //     .then(res => {

            //         var filePath = res.Table1[0].Value;
            //         window.location.href = filePath;
            //     })
            //     .catch(error => {
            //         console.log("Error Encountered");
            //     });
            $("#loader-div").show();
            fetch(BaseURL + "/Common/DownloadtoExcelBenchmarkDetails?GroupID=" + this.props.groupId + "&LanguageID=" + this.props.languageId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: "Bearer " + sessionStorage.getItem("adal.idtoken"),
                    "Cache-Control": "no-cache, no-store, must-revalidate",
                    Pragma: "no-cache"
                }
                
            })
            .then(response => response.blob())
                .then((blob) => {
                    const BlobUrl = window.URL.createObjectURL(blob);
                    var downLink = document.createElement("a");
                    downLink.href = BlobUrl;
                    downLink.download = "BenchmarkDetails.xlsx";
                    document.body.appendChild(downLink);
                    downLink.click();
                    downLink.remove();
                    $("#loader-div").hide();
                    alert(i18n.t("filedownloaded"));
                    }
                )
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    saveBenchmark(benchmarkArr) {

        try {
            $("#loader-div").show();
            let benchmarkData = benchmarkArr;
            let valid = true, errMsg = "";
            let benchmarkSaveArr = [];
            let benchmarkLength = benchmarkData.length;
            valid = benchmarkLength > 0 ? true : false;
            errMsg = benchmarkLength > 0 ? i18n.t("SelectLeverKPAMetricImpactedforallPMMs") : i18n.t("pmmDetailsNotLoaded")

            benchmarkData.map(benchmarkRow => {

                let benchmarkObj = {
                    BenchmarkRowId: 0,
                    BenchmarkTypeID: benchmarkRow.BenchMarkTypeID == undefined ? 0 : benchmarkRow.BenchMarkTypeID,
                    BenchmarkTypeName: benchmarkRow.BenchmarkType == null ? i18n.t('notPresent') : benchmarkRow.BenchmarkType,
                    CapabilityName: benchmarkRow.CapabilityName == null ? i18n.t('notPresent') : benchmarkRow.CapabilityName,
                    TowerName: benchmarkRow.TowerName == null ? i18n.t('notPresent') : benchmarkRow.TowerName,
                    MetricName: benchmarkRow.MetricName == null ? i18n.t('notPresent') : benchmarkRow.MetricName,
                    IndustryID: benchmarkRow.IndustryID == undefined ? 0 : benchmarkRow.IndustryID,
                    IndustryName: benchmarkRow.IndustryName == null ? i18n.t('notPresent') : benchmarkRow.IndustryName,
                    RegionName: benchmarkRow.RegionName == null ? i18n.t('notPresent') : benchmarkRow.RegionName,
                    MetricType: benchmarkRow.MetricType == null ? i18n.t('notPresent') : benchmarkRow.MetricType,
                    InternalBenchmark: benchmarkRow.UpperQuartileInternalBenchmark,
                    loggedInUserId: this.props.enterpriseId
                }


                benchmarkSaveArr.push(benchmarkObj);
            })
            if (valid) {


                var isError = false;
                if (isError == false) {
                    let BenchmarkObj = {
                        benchmarkDetails: benchmarkSaveArr
                    }

                    request("/Common/ValidateBenchmark?LanguageID=" + this.props.languageId, 'POST', BenchmarkObj)
                        .then(data => {

                            let benchmarkArr = [];
                            benchmarkArr = data.Table;
                            let uniqueBenchmark = []

                            let duplicateBenchmark = benchmarkArr.filter(item => {
                                if (uniqueBenchmark.find(i => i.BenchmarkTypeName === item.BenchmarkTypeName && i.CapabilityName === item.CapabilityName && i.TowerName === item.TowerName && i.MetricName === item.MetricName && i.IndustryName === item.IndustryName && i.RegionName === item.RegionName)) {
                                    return true
                                }
                                uniqueBenchmark.push(item)
                                return false;
                            });

                            benchmarkArr.map(benchmarkRow => {
                                if (duplicateBenchmark.find(i => i.BenchmarkTypeName === benchmarkRow.BenchmarkTypeName && i.CapabilityName === benchmarkRow.CapabilityName && i.TowerName === benchmarkRow.TowerName && i.MetricName === benchmarkRow.MetricName && i.IndustryName === benchmarkRow.IndustryName && i.RegionName === benchmarkRow.RegionName)) {
                                    benchmarkRow.ValidationMessage == null ? benchmarkRow.ValidationMessage = i18n.t('duplicateRecord') + "." : benchmarkRow.ValidationMessage = i18n.t('duplicateRecord') + "; " + benchmarkRow.ValidationMessage
                                }
                                if (benchmarkRow.BenchmarkTypeName == i18n.t('notPresent')) {
                                    benchmarkRow.ValidationMessage == null ? benchmarkRow.ValidationMessage = i18n.t('pleaseEnterBenchmarkTypeForAllLineItems') : benchmarkRow.ValidationMessage = i18n.t('pleaseEnterBenchmarkTypeForAllLineItems') + "; " + benchmarkRow.ValidationMessage
                                    benchmarkRow.BenchmarkTypeName = "";
                                }
                                if (benchmarkRow.CapabilityName == i18n.t('notPresent')) {
                                    benchmarkRow.ValidationMessage == null ? benchmarkRow.ValidationMessage = i18n.t('pleaseEnterCapabilityNameForAllLineItems') : benchmarkRow.ValidationMessage = i18n.t('pleaseEnterCapabilityNameForAllLineItems') + "; " + benchmarkRow.ValidationMessage
                                    benchmarkRow.CapabilityName = "";
                                }
                                if (benchmarkRow.TowerName == i18n.t('notPresent')) {
                                    benchmarkRow.ValidationMessage == null ? benchmarkRow.ValidationMessage = i18n.t('pleaseEnterTowerNameForAllLineItems') : benchmarkRow.ValidationMessage = i18n.t('pleaseEnterTowerNameForAllLineItems') + "; " + benchmarkRow.ValidationMessage
                                    benchmarkRow.TowerName = "";
                                }
                                if (benchmarkRow.MetricName == i18n.t('notPresent')) {
                                    benchmarkRow.ValidationMessage == null ? benchmarkRow.ValidationMessage = i18n.t('pleaseEnterMetricNameForAllLineItems') : benchmarkRow.ValidationMessage = i18n.t('pleaseEnterMetricNameForAllLineItems') + "; " + benchmarkRow.ValidationMessage
                                    benchmarkRow.MetricName = "";
                                }
                                if (benchmarkRow.MetricType == i18n.t('notPresent')) {
                                    benchmarkRow.ValidationMessage == null ? benchmarkRow.ValidationMessage = i18n.t('pleaseEnterMetricTypeForAllLineItems') : benchmarkRow.ValidationMessage = i18n.t('pleaseEnterMetricTypeForAllLineItems') + "; " + benchmarkRow.ValidationMessage
                                    benchmarkRow.MetricType = "";
                                }
                                if (benchmarkRow.IndustryName == i18n.t('notPresent')) {
                                    benchmarkRow.IndustryName = "";
                                }
                                if (benchmarkRow.RegionName == i18n.t('notPresent')) {
                                    benchmarkRow.RegionName = "";
                                }
                            });



                            var resultValid = benchmarkArr.filter(function (item) {
                                return (item.ValidationMessage == null);
                            });
                            var resultInValid = benchmarkArr.filter(function (obj) {
                                return (obj.ValidationMessage != null);
                            });
                            if (resultInValid.length > 0) {
                                this.setState({ benchmarkDetails: resultInValid });
                                this.setState({ popUpBenchmark: true });
                            }
                            if (resultValid.length > 0) {
                                let BenchmarkObj = {
                                    benchmarkDetails: resultValid
                                }
                                request("/PMM/SaveBenchmark?LanguageID=" + this.props.languageId, 'POST', BenchmarkObj)
                                    .then(res => {

                                        if (res.outputmessage == i18n.t('success')) {
                                            alert(res.outputmessage);
                                        }
                                        else {
                                            alert(i18n.t("BenchmarkDetailsnotsaved"));
                                        }
                                        $("#loader-div").hide();
                                    })
                                    .catch(error => {
                                        $("#loader-div").hide();
                                        console.log("Error Encountered");
                                    })
                            }
                        })
                        .catch(error => {
                            console.log("Error Encountered");
                        });
                    $("#loader-div").hide();
                }
                else {
                    $("#loader-div").hide();
                    alert(i18n.t("pleasecorrectthevalues"));
                }

            } else {
                $("#loader-div").hide();
                alert(errMsg);
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    clearUploadedFile = () => {
        try {
            document.getElementById("fUpload").value = "";;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    uploadFile() {

        try {            
            //window.tfo_event("PMM-D-14", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            var fileData = new FormData();
            fileData.append("File", this.refs.file.files[0]);
            var uploadedFile = this.refs.file.files[0];

            var that = this;
            var reader = new FileReader();
            reader.readAsDataURL(uploadedFile);

            $("#loader-div").show();
            fetch(BaseURL + "/Common/ValidateBenchmarkUpload?LanguageID=" + this.props.languageId, {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                },
                body: fileData
            }).then(r => r.json())
                .then(data => {
                    if (data.Table1.length !== 0) {
                        if (data.Table1[0].ErrorMessage!==undefined) {
                            alert(i18n.t("pleaseUploadValidFile"));
                            this.clearUploadedFile();
                            $("#loader-div").hide();
                        }
                        else if(data.Table.filter(obj=> obj.ValidationMessage===null).length === data.Table.length){
                            alert(i18n.t("UploadedSuccessfully"));
                            this.clearUploadedFile();
                            $("#loader-div").hide();
                        }
                    }
                    else {
                        let benchmarkArr = [];
                        benchmarkArr = data.Table;
                        if (benchmarkArr.length > 0) {

                            let uniqueBenchmark = []

                            let duplicateBenchmark = benchmarkArr.filter(item => {
                                if (uniqueBenchmark.find(i => i.BenchmarkTypeName === item.BenchmarkTypeName && i.CapabilityName === item.CapabilityName && i.TowerName === item.TowerName && i.MetricName === item.MetricName && i.IndustryName === item.IndustryName && i.RegionName === item.RegionName)) {
                                    return true
                                }
                                uniqueBenchmark.push(item)
                                return false;
                            });

                            benchmarkArr.map(benchmarkRow => {
                                if (duplicateBenchmark.find(i => i.BenchmarkTypeName === benchmarkRow.BenchmarkTypeName && i.CapabilityName === benchmarkRow.CapabilityName && i.TowerName === benchmarkRow.TowerName && i.MetricName === benchmarkRow.MetricName && i.IndustryName === benchmarkRow.IndustryName && i.RegionName === benchmarkRow.RegionName)) {
                                    benchmarkRow.ValidationMessage == null ? benchmarkRow.ValidationMessage = i18n.t('duplicateRecord') + "." : benchmarkRow.ValidationMessage = i18n.t('duplicateRecord') + "; " + benchmarkRow.ValidationMessage
                                }
                                if (benchmarkRow.BenchmarkTypeName == i18n.t('notPresent')) {
                                    benchmarkRow.ValidationMessage == null ? benchmarkRow.ValidationMessage = i18n.t('pleaseEnterBenchmarkTypeForAllLineItems') : benchmarkRow.ValidationMessage = i18n.t('pleaseEnterBenchmarkTypeForAllLineItems') + "; " + benchmarkRow.ValidationMessage
                                    benchmarkRow.BenchmarkTypeName = "";
                                }
                                if (benchmarkRow.CapabilityName == i18n.t('notPresent')) {
                                    benchmarkRow.ValidationMessage == null ? benchmarkRow.ValidationMessage = i18n.t('pleaseEnterCapabilityNameForAllLineItems') : benchmarkRow.ValidationMessage = i18n.t('pleaseEnterCapabilityNameForAllLineItems') + "; " + benchmarkRow.ValidationMessage
                                    benchmarkRow.CapabilityName = "";
                                }
                                if (benchmarkRow.TowerName == i18n.t('notPresent')) {
                                    benchmarkRow.ValidationMessage == null ? benchmarkRow.ValidationMessage = i18n.t('pleaseEnterTowerNameForAllLineItems') : benchmarkRow.ValidationMessage = i18n.t('pleaseEnterTowerNameForAllLineItems') + "; " + benchmarkRow.ValidationMessage
                                    benchmarkRow.TowerName = "";
                                }
                                if (benchmarkRow.MetricName == i18n.t('notPresent')) {
                                    benchmarkRow.ValidationMessage == null ? benchmarkRow.ValidationMessage = i18n.t('pleaseEnterMetricNameForAllLineItems') : benchmarkRow.ValidationMessage = i18n.t('pleaseEnterMetricNameForAllLineItems') + "; " + benchmarkRow.ValidationMessage
                                    benchmarkRow.MetricName = "";
                                }
                                if (benchmarkRow.MetricType == i18n.t('notPresent')) {
                                    benchmarkRow.ValidationMessage == null ? benchmarkRow.ValidationMessage = i18n.t('pleaseEnterMetricTypeForAllLineItems') : benchmarkRow.ValidationMessage = i18n.t('pleaseEnterMetricTypeForAllLineItems') + "; " + benchmarkRow.ValidationMessage
                                    benchmarkRow.MetricType = "";
                                }
                                if (benchmarkRow.IndustryName == i18n.t('notPresent')) {
                                    benchmarkRow.IndustryName = "";
                                }
                                if (benchmarkRow.RegionName == i18n.t('notPresent')) {
                                    benchmarkRow.RegionName = "";
                                }
                            });

                            var resultInValid = benchmarkArr.filter(function (obj) {
                                return (obj.ValidationMessage != null);
                            });
                            if (resultInValid.length > 0) {
                                alert(i18n.t("uploadFailed"));
                                this.setState({ benchmarkDetails: resultInValid });
                                $("#loader-div").hide();
                                this.setState({ popUpBenchmark: true });
                            }
                            else {
                                alert(i18n.t("UploadedSuccessfully"));
                                this.clearUploadedFile();
                                $("#loader-div").hide();
                            }
                           
                        }
                    }
                })
                .catch(error => {
                    $("#loader-div").hide();
                    console.log("Error Encountered");
                })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    downloadTemplate(params) {        
        //window.tfo_event("PMM-D-15", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
        /*
        request("/Common/DownloadExcelBenchmark?GroupID=" + params.groupId + "&LanguageID=" + params.languageId, 'GET')
            .then(response => response.blob())
                .then((blob) => {
                    const BlobUrl = window.URL.createObjectURL(blob);
             
              
                     var downLink = document.createElement("a");
                     downLink.href = BlobUrl;
                     downLink.download = `BenchmarkExcelUpload.xlsx`;
                     document.body.appendChild(downLink);
                     downLink.click();
                     downLink.remove();
        
                    
                } 
        */
        try {
            $("#loader-div").show();
            fetch(BaseURL + "/Common/DownloadExcelBenchmark?GroupID=" + params.groupId + "&LanguageID=" + params.languageId, {
                method: 'GET',
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                }
                
            })
            .then(response => response.blob())
                .then((blob) => {
                    const BlobUrl = window.URL.createObjectURL(blob);
                    var downLink = document.createElement("a");
                    downLink.href = BlobUrl;
                    downLink.download = `BenchmarkExcelUpload.xlsx`;
                    document.body.appendChild(downLink);
                    downLink.click();
                    downLink.remove();
                    $("#loader-div").hide();
                    alert(i18n.t("filedownloaded"));
                    }
                )
                //$("#loader-div").hide();
                //alert(i18n.t("filedownloaded"));
                
        } catch (error) {
            console.log("Error Encountered");
        }
                 /*{

                $("#loader-div").hide();
                //window.location.href = res;
                //console.log(res);
                window.open(res,'_blank','noopener noreferrer');
                alert(i18n.t("filedownloaded"));
            }
            */     
    }
    fileUploaded(e) {
        try {
            //window.tfo_event("PMM-D-17", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            var fileExtension = ['xls'];
            var fileName = e.currentTarget.files[0].name.split('\\').pop();
            fileName = fileName.substr(0, fileName.lastIndexOf('.'));
            var file = e.currentTarget.files[0];

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    closeBenchmarkPopUp() {

        try {
            this.setState({
                popUpBenchmark: false
            });
            this.clearUploadedFile();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    downloadInvalidBenchmark() {

        try {
            var objThis = this;
            window.removeEventListener("beforeunload", this.unloadEvent);
            var fileData = new FormData();

            fileData.append("File", this.refs.file.files[0]);
            var uploadedFile = this.refs.file.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(uploadedFile);


            fetch(BaseURL + "/Common/DownloadtoExcelInvalidBenchmark?LanguageID=" + this.props.languageId, {
                method: 'POST',
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem("adal.idtoken")
                },
                body: fileData
            })
                .then(r => r.json())
                    .then(response => response.blob())
                        .then((blob) => {
                            const BlobUrl = window.URL.createObjectURL(blob);
                            var downLink = document.createElement("a");
                            downLink.href = BlobUrl;
                            downLink.download = `PMMMaster_Config.xlsx`;
                            document.body.appendChild(downLink);
                            downLink.click();
                            downLink.remove();
                            $("#loader-div").hide();
                            alert(i18n.t("filedownloaded"));
                            }
                        )

                // (response => {

                //     var filePath = response.Table1[0].Value;
                //     window.location.href = filePath;
                //     $("#loader-div").hide();
                // })
                .catch(error => {
                    console.log("Error Encountered");
                });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        let { t } = this.props;

        return (
            <section className="content">

                <div className="innerpage-hd">
                    <iframe id="my_iframe" style={{ "display": "none" }}></iframe>
                    <a type="button" className="btn btn-info btn-back pull-right" data-content="HOME" data-url="/Home" onClick={this.onNavigationClick}><img src={backArrowIcon} data-content="HOME" data-url="/Home" /> {i18n.t('bACKTOHOME')}</a>
                    <a type="button" className="btn btn-info btn-back pull-right" data-content="HOME" onClick={this.downloadDataDump}><img src={downloadIcon} data-content="DATA DUMP" /> {i18n.t('dATADUMP')}</a>
                    <a type="button" className="btn btn-info btn-orange btn-back pull-right" data-content="HOME" onClick={this.DownloadtoExcelProjectPrioritization.bind(this)}><img src={downloadIcon} data-content="ExportPrioritizedProject" title={i18n.t('exportPrioritizedProject')} /></a>
                    <a type="button" className="btn btn-info btn-lightblue btn-back pull-right" data-content="HOME" onClick={this.DownloadtoExcelSubmittedBOILI.bind(this)}><img src={downloadIcon} data-content="ExportSubmittedBOILI" title={i18n.t('exportSubmittedBOILI')} /></a>
                    <a type="button" className="btn btn-info btn-orange btn-back pull-right" data-content="HOME" onClick={this.DownloadtoExcelActiveReport.bind(this)}><img src={downloadIcon} data-content="ExportActiveReport" title={i18n.t('Export Historical Report')} /></a>
                   {/* <a type="button" className="btn btn-info btn-lightblue btn-back pull-right" data-content="HOME" onClick={this.DownloadtoHistoryReport.bind(this)}><img src={downloadIcon} data-content="HistoryReport" title={i18n.t('HistoryReport')} /></a> */}

                    <ul className="adminMenu">
                        {this.state.pageNames.includes("PMMConfiguration") ?
                            <li className="active">
                                <a className="menu3" data-content="MASTERS Home" data-url="/PMMConfiguration" onClick={this.onNavigationClick}>{i18n.t('mASTERS')}</a>

                            </li>
                            : null}

                        {this.state.pageNames.includes("UserManagement") ?
                            <li>
                                <a className="menu1" data-content="User Management" data-url="/UserManagement" onClick={this.onNavigationClick}>{i18n.t('userManagement')}</a>

                            </li>
                            : null}

                        {/* {this.state.pageNames.includes("DealManagement") ?
                            <li>
                                <a className="menu4" data-content="Deal Management" data-url="/DealManagement" onClick={this.onNavigationClick}>{i18n.t('dealManagement')}</a>
                            </li>
                            : null} */}

                        {this.state.pageNames.includes("BOIConfiguration") ?
                            < li >
                                <a className="menu2" data-content="PMM Configuration" data-url="/BOIConfiguration" onClick={this.onNavigationClick}>{i18n.t('pMMConfiguration')}</a>
                            </li>
                            : null}

                        {this.state.pageNames.includes("UnlockAssessment") ?
                            <li>
                                <a className="menu5" data-content="Unlock Assessment" data-url="/UnlockAssessment" onClick={this.onNavigationClick}>{i18n.t('unlockAssessment')}</a>
                            </li>
                            : null}

                    </ul>

                </div>
                <section className="bodydata">
                    <div className="bodydata-full">
                        <div className="box-plain">
                            <h4>{i18n.t('masterEntry')}</h4>
                            <div className="row hdbottom">
                                <p>{i18n.t('forCreatingMastersPleaseSelectTheRespective')} </p>
                                <div>
                                    <div>
                                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} className="col-md-6 nopadding configDropdown" id="ddl_congigPMM" labelKey="label" placeholder={i18n.t('select')} onChange={this.SelectToConfigure.bind(this)} options={this.state.MasterListValues} selected={this.state.MasterListsValue} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                    </div>
                                    {(this.state.showUpload == true && this.props.groupId == "1") ?
                                        <div>
                                            <div className="btn-group-md pull-right">
                                                <button type="button" id="btn_upload" className="btn btn-success" onClick={this.uploadFile}>{i18n.t('upload')}</button>
                                                <button type="button" id="btn_downloadTemp" className="btn btn-danger" onClick={() => this.downloadTemplate(this.props)}>{i18n.t('downloadTemplate')}</button>
                                                <button type="button" id="btn_downloadBenchmarkDetails" className="btn btn-info" onClick={this.DownloadtoExcelBenchmarkDetails}>{i18n.t('downloadBenchmarkDetails')}</button>
                                            </div>
                                            <div className="fright upload-btn upload-select">
                                                <label id="lbl_pmm_selFile">{i18n.t('selectFile')}</label>
                                                <input type="file" ref="file" name="PMMFileUpload" id="fUpload" accept="application/vnd.ms-excel" onChange={this.fileUploaded} />
                                            </div>
                                        </div>
                                        : null
                                    }
                                </div>
                                <h4>{this.state.SelectedType}</h4>
                            </div>
                            {this.state.popUpDisplay && <NavigationConfirmation show={this.state.popUpDisplay}
                                navigationPage={this.state.navigationPage} isEditAcitve={this.state.isUnSaved}
                                url={this.state.toURL} closeCallback={this.closeNavigationPopUp}
                                setDropValCallback={this.setDropValue} dropValue={this.state.dropValue} />}
                            {this.state.popUpBenchmark && <BenchmarkValidationPopup benchmarkUploadData={this.state.benchmarkDetails} show={this.state.popUpBenchmark} closeCallback={this.closeBenchmarkPopUp} downloadInvalidBenchmark={this.downloadInvalidBenchmark} />}


                            <Switch>
                                <Route path='/PMMConfiguration/OG' render={() => <OG editFun={this.isEditActive} />} />
                                <Route path='/PMMConfiguration/Industry' render={() => <Industry editFun={this.isEditActive} />} />
                                <Route path='/PMMConfiguration/Capability' render={() => <Capability editFun={this.isEditActive} />} />
                                <Route path='/PMMConfiguration/Tower' render={() => <Tower editFun={this.isEditActive} />} />
                                <Route path='/PMMConfiguration/Service Component' render={() => <ServiceComponent editFun={this.isEditActive} />} />


                                <Route path='/PMMConfiguration/BOI LI' render={() => <BOILIMaster showDownloadUpload={this.showDownloadUpload.bind(this)} editFun={this.isEditActive} />} />
                                <Route path='/PMMConfiguration/KPA' render={() => <KPA editFun={this.isEditActive} />} />
                                <Route path='/PMMConfiguration/Lever' render={() => <Lever editFun={this.isEditActive} />} />
                                <Route path='/PMMConfiguration/Metrics' component={Metrics} />
                                <Route path='/PMMConfiguration/Region' render={() => <RegionEntry editFun={this.isEditActive} />} />
                            </Switch>
                        </div>
                    </div>
                </section>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        languageId: state.languageId,
        enterpriseId: state.enterpriseId,
        groupId: state.groupId,
        pageName: state.pageName,
        groupName: state.groupName
    }
}

PMMConfiguration = connect(mapStateToProps)(withTranslation()(PMMConfiguration));
export default withRouter(PMMConfiguration);